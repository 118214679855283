import React from 'react';
import './about.css';

const texts = {
    en: {
        h1: 'About the School',
        p1: 'Learning to skate, like learning any skill, is a super individual process. In our case, some individuals would like to dance on their board and carve all over town, others would love an alternative to their bike to spice up their commutes - some just want to try something new or learn a few tricks.',
        p2: 'To us, this means the workshops need to be just as individual - tailored to your needs. We will be learning what you want to learn and the tools required for it. The past 5 years of teaching have made us super confident in that we are a good fit for anyone interested in learning about the art of the wooden plank commute and having a good time while doing so.',
        p3: `We're looking forward to meeting up and rolling around some. We are.`
    },
    de: {
        h1: 'Über unsere Schule',
        p1: `Sich dem Lernen des Longboardens hinzugeben ist ein Prozess, der individueller nicht sein könnte. Einige interessieren sich dafür, auf ihren Boards zu tanzen und durch die Stadt zu surfen, während andere nach einer Alternative zum Fahrrad suchen. Oder wieder andere, die einfach nur etwas Neues lernen möchten. `,
        p2: 'Für uns bedeutet das, dass unsere Kurse mindestens genauso individuell gestaltet werden müssen. Wir finden heraus, was Dir wichtig ist und wie wir auf bestem Wege gemeinsam dein Ziel erreichen. Seit nun mehr über 5 Jahren bringen wir Teilnehmern allen Alters und aus allen Ecken der Welt mit voller Freude das Skaten bei und versuche unser gesammeltes Wissen an die nächste Generation von Rollbegeisterten weiterzugeben. ',
        p3: 'Wir freuen uns darauf, uns mit Dir zu treffen und eine Runde zu rollen.'

    }
}

export default function About({ locale }) {
    return (
        <div className="about-container">
            <h1>{texts[`${locale.key}`].h1}</h1>
            <p>{texts[`${locale.key}`].p1}</p>
            <p>{texts[`${locale.key}`].p2}</p>
            <p>{texts[`${locale.key}`].p3}</p>
        </div>
    )




}


