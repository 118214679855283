import React from 'react';
import './faq.css';

const texts = {
    en: {
        h1: 'FAQ',
        q1: {
            q: 'How much is it?',
            a: 'A private 90-minute lesson is 40 EUR/person. If you bring a friend, you both save 5 EUR each. If you need to rent one of our boards, it will be an additional 5 EUR/person.'
        },
        q2: {
            q: 'Where do we meet?',
            a: 'Workshops will take place on Winterfeldtplatz in Berlin, Schöneberg.'
        },
        q3: {
            q: `What if it's raining?`,
            a: 'In case of unsuitable weather conditions, we will have to reschedule our lesson.'
        },
        q4: {
            q: 'Do you have gift cards?',
            a: `We do! We can make you one of our gift cards for you to print out at home. `,
            link: 'Take a look!'
        }
    },
    de: {
        h1: 'Fragen',
        q1: {
            q: 'Wie teuer ist der Spaß?',
            a: 'Ein 90-minütiger Kurs kostet 40€/Person. Bringst Du noch einen Freund mit, zahlt ihr beide bloß 35€/Person. Für ein Leihboard kommen 5€/Person dazu.'
        },
        q2: {
            q: 'Wo findet der Workshop statt?',
            a: 'In der Regel finden unsere Kurse auf dem Winterfeldtplatz in Berlin, Schöneberg statt.'
        },
        q3: {
            q: 'Was passiert wenn das Wetter nicht mitspielt?',
            a: 'In so einem Fall müssten wir uns leider um einen neuen Termin bemühen.',
        },
        q4: {
            q: 'Habt ihr Gutscheinkarten zum Verschenken?',
            a: 'Yep! Wir können Dir eine unserer Gutscheinkarten zum Ausdrucken schicken. Schick uns einfach eine E-Mail. ',
            link: 'Schau mal rein!'
        }
    }
}

export default function FAQ({ locale, voucher, setVoucher }) {
    return (
        <div className="faq-container">
            <h1>{texts[`${locale.key}`].h1}</h1>
            <div className="faq-question">
                <h2>{texts[`${locale.key}`].q1.q}</h2>
                <p>{texts[`${locale.key}`].q1.a}</p>
            </div>
            <div className="faq-question">
                <h2>{texts[`${locale.key}`].q2.q}</h2>
                <p>{texts[`${locale.key}`].q2.a}</p>
            </div>
            <div className="faq-question">
                <h2>{texts[`${locale.key}`].q3.q}</h2>
                <p>{texts[`${locale.key}`].q3.a}</p>
            </div>
            <div className="faq-question">
                <h2>{texts[`${locale.key}`].q4.q}</h2>
                <p>{texts[`${locale.key}`].q4.a}<span className="link-span" onClick={() => setVoucher(true)}>{texts[`${locale.key}`].q4.link}</span></p>
            </div>
        </div>
    )
}
