import React from 'react';
import './teacher.css';

const texts = {
    en: {
        h1: `Teacher`,
        p1: `Jeff likes vegetarian hot dogs, has been wearing the same shirts for the past 10 years and can hold a nose manual for half an eternity.`,
        p2: `In 2013, Jeff won the European Longboard Dancing Championship and in 2014 he's won the 2nd edition of the now infamous 'So..You Can Longboard Dance' World Championships.`,
        p3: `When he isn't skating, he enjoys playing off-color video games and coding computer applications, like this one you're browsing right now.`,
        ul: `Here's some random media Jeff enjoys and recommends:`,
        li1: `(album)`,
        li2: `(movie)`,
        li3: `(video game)`,
        li4: `(book)`
    },
    de: {
        h1: `Lehrer`,
        p1: `Jeff isst gern vegetarische Hot Dogs, trägt schon seit Jahren dieselben T-Shirts und kann einen Nose Manual easy eine halbe Ewigkeit lang halten.`,
        p2: `Im Jahr 2013 hat Jeff die Europameisterschaft im Longboard Dancing gewonnen und in 2014 gewann er die zweite Ausgabe der mittlerweile berühmt-berüchtigten "So..You Can Longboard Dance" Weltmeisterschaften.`,
        p3: `Wenn Jeff nicht am Skaten ist, spielt er gerne Indie-Videospiele und arbeitet an Computer-Applikationen, wie die, die Du gerade vor Dir hast.`,
        ul: `Hier ein paar beliebige Titel, die Jeff empfiehlt:`,
        li1: `(Album)`,
        li2: `(Film)`,
        li3: `(Videospiel)`,
        li4: `(Buch)`
    }
}

export default function Teacher({ locale }) {
    return (
        <div className="teacher-container">
            <h1>{texts[`${locale.key}`].h1}</h1>
            <div className="profile-container">
                <img src={require('../logo/jeff_profile.jpg')} alt="Jeff Ellis profile" />
                <div className="profile">
                    <p>{texts[`${locale.key}`].p1}</p>
                    <p>{texts[`${locale.key}`].p2}</p>
                    <p>{texts[`${locale.key}`].p3}</p>
                    <ul>{texts[`${locale.key}`].ul}
                        <li>{`A Flourish and a Spoil - The Districts ${texts[`${locale.key}`].li1}`}</li>
                        <li>{`The Wolf of Wall Street ${texts[`${locale.key}`].li2}`}</li>
                        <li>{`Dead Cells ${texts[`${locale.key}`].li3}`}</li>
                        <li>{`What I talk about when I talk about Running - Haruki Murakami ${texts[`${locale.key}`].li4}`}</li>
                    </ul>
                </div>
            </div>
        </div>
    )
}
