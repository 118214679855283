import React from 'react';
import './footer.css';
import { default as InstaLogo } from '../logo/instagram.svg';
import { default as FBLogo } from '../logo/facebook.svg';

const texts = {
    en: {
        p: `If you have any questions, feel free to send us an `,
        subject: 'I want to book a Beginner lesson'
    },
    de: {
        p: `Bei weiteren Fragen, schreib uns gerne eine `,
        subject: 'Ich möchte einen Anfängerkurs buchen'
    }
}

export default function Footer({ locale }) {

    const openEmail = (e) => {
        window.location.href = `mailto:jeff@awhoulongboardschool.com?subject=Hi!`
    }
    return (
        <div className="footer-container">
            <div className="socials-container">
                <a href="https://www.instagram.com/awhoulongboardschool" target="_blank" rel="noopener noreferrer"><img src={InstaLogo} alt="Instagram logo" /></a>
                <a href="https://www.facebook.com/awhoulongboardschool" target="_blank" rel="noopener noreferrer"><img src={FBLogo} alt="Facebook logo" /></a>
            </div>
            <div className="contact-container">
                <p>{texts[`${locale.key}`].p}<span value={texts[`${locale.key}`].subject} onClick={(e) => openEmail(e)}>E-Mail</span></p>
            </div>
            <div className="copyright-container">
                <p>© 2022 AWHOU! Longboard School</p>
            </div>
        </div>
    )
}
