import React from 'react';
import './workshops.css';

const texts = {
    en: {
        h1: 'Workshops',
        button: 'Book now',
        beginner_h2: `Beginner's Class`,
        beginner_p: `We'll learn how longboards work. Pushing, braking, carving and everything else necessary to build a nice foundation and roll around safely!`,
        beginner_subject: 'I want to book a Beginner lesson',
        dancing_h2: `Longboard Dancing`,
        dancing_p: `Dancing doesn't have to be complicated. We'll break it down to the basics and attempt our first turns and simple steps.`,
        dancing_subject: 'I want to book a Longboard Dancing lesson',
        freestyle_h2: 'Freestyle Skating',
        freestyle_p: `Discover Pivots and Shuvits. If you already know how to get around and want to explore your board further, this one's for you.`,
        freestyle_subject: 'I want to book a Freestyle Skating lesson'
    },
    de: {
        h1: 'Kursangebot',
        button: 'Teilnehmen',
        beginner_h2: 'Anfängerkurs',
        beginner_p: `Wir finden heraus wie ein Longboard eigentlich funktioniert. Abstoßen, bremsen und alles was sonst noch wichtig ist, um sicher durch die Stadt zu rollen.`,
        beginner_subject: 'Ich möchte einen Anfängerkurs buchen',
        dancing_h2: `Longboard Dancing`,
        dancing_p: 'Longboard Dancing ist gar nicht so kompliziert wie es aussieht. Wir fangen klein mit deinen ersten Schritten an und tasten uns langsam an kuriosere Maneuver heran.',
        dancing_subject: 'Ich möchte einen Longboard Dancing Workshop buchen',
        freestyle_h2: 'Freestyle Skaten',
        freestyle_p: ' Wenn Du nach einer neuen Herausforderung suchst, bist Du hier richtig. Wir lernen Pivots und Shuvits und schauen, was man sonst noch so auf deinem Board anstellen kann.',
        freestyle_subject: 'Ich möchte einen Freestyle-Skate-Kurs buchen'
    }
}

export default function Workshops ({ locale }) {

    const openEmail = (e) => {
        window.location.href = `mailto:jeff@awhoulongboardschool.com?subject=${e.target.value}`
    }
    return (
        <div className="workshops-container">
            <h1>{texts[`${locale.key}`].h1}</h1>
            <div className="cards-container">
                <div className="card">
                    <h2>{texts[`${locale.key}`].beginner_h2}</h2>
                    <p>{texts[`${locale.key}`].beginner_p}</p>
                    <button value={texts[`${locale.key}`].beginner_subject} onClick={openEmail}>{texts[`${locale.key}`].button}</button>
                </div>
                <div className="card">
                    <h2>{texts[`${locale.key}`].dancing_h2}</h2>
                    <p>{texts[`${locale.key}`].dancing_p}</p>
                    <button value={texts[`${locale.key}`].dancing_subject} onClick={openEmail}>{texts[`${locale.key}`].button}</button>
                </div>
                <div className="card">
                    <h2>{texts[`${locale.key}`].freestyle_h2}</h2>
                    <p>{texts[`${locale.key}`].freestyle_p}</p>
                    <button value={texts[`${locale.key}`].freestyle_subject} onClick={openEmail}>{texts[`${locale.key}`].button}</button>
                </div>
            </div>
        </div>
    )
}
