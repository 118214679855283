import { useState } from 'react';
import mainLogo from './logo/als_logo.png';
import './app.css';
import Workshops from './components/Workshops';
import Teacher from './components/Teacher';
import About from './components/About';
import FAQ from './components/FAQ';
import Footer from './components/Footer';
import Locale from './components/Locale';
import Voucher from './components/Voucher';
import Video from './components/Video';

// ################ CHANGE LOG / TODO ################

// 1 - add ALS promo video✔
// 2 - fix width of locale container ✔
// 3 - replace imgur voucher link with on-site image pop-up ✔
// 4 - add small teacher profile ✔
// 5 - change meta description to German and look into adding additional keywords ✔

// - increased main container width on mobile to 85% [App.js] ✔
// - fixed locale container width to 3rem [Locale.js] ✔
// - removed log statement from openEmail function [Footer.js] ✔
// - added rel="noopener noreferrer" to social links [Footer.js] ✔

function App() {

  // locale stores user's language preference
  const [locale, setLocale] = useState({ key: 'en', text: 'DE'});
  const [voucher, setVoucher] = useState(false);

  const closeModal = () => {
    if(voucher === true) {
      setVoucher(false)
    }
  }
  return (
    <div className="app-container" onClick={closeModal}>
      <img src={mainLogo} alt="AWHOU! Longboard School Logo" />
      <div className="main-container">
        <Locale locale={locale} setLocale={setLocale} />
        <About locale={locale} />
        <Video />
        <Workshops locale={locale} />
        <Teacher locale={locale}/>
        <FAQ locale={locale} setVoucher={setVoucher} />
        <Voucher voucher={voucher} setVoucher={setVoucher} />
      </div>
      <Footer locale={locale} />
    </div>
  );
}

export default App;
