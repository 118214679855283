import React, { useEffect } from 'react';
import video from '../video/als_1080p_30fps.mp4';
import './video.css';

export default function Video() {
    useEffect(() => {
        const videoLocation = document.querySelector('video')
        if (window.innerWidth > 992) {
            videoLocation.setAttribute('autoplay', '')
            videoLocation.play()
        }
    })
    if (window.innerWidth > 992) {
        return (
            <div className="video-wrapper">
                <video id='video-bg' preload="none" loop="loop" muted>
                    <source src={video} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            </div>
        )
    } else {
        return null
    }
}
