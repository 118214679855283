import React from 'react';
import './locale.css';
import globe from '../logo/globe.png';

export default function Locale({ locale, setLocale }) {

  return (
    <div className="locale-container" onClick={() => locale.key === 'en' ? setLocale({ key: 'de', text: 'EN'}) : setLocale({ key: 'en', text: 'DE'})}>
        <img src={globe} alt="globe icon"></img>
        <p>{locale.text}</p>
        </div>
  )
}
