import React from 'react';
import './voucher.css';

export default function Voucher({ voucher, setVoucher }) {
    if (voucher === true) {
        return (
            <div className="voucher-container">
                <img src={require('../logo/AwhouLS_Voucher.jpg')} alt="AWHOU! Longboard School Voucher Example" onClick={() => setVoucher(false)} />
            </div>
        )
    } else {
        return null
    }
}
